import type { CurrencyCode, UnknownEvent } from '@faststore/sdk'
import { sendAnalyticsEvent, useSession } from '@faststore/sdk'
import { useCallback } from 'react'
import type { CartItem } from 'src/sdk/cart/validate'

import { usePageTypeContext } from 'src/contexts/page-type-context'
import { useApplicationInsightsAPI } from '../insights/useApplicationInsightsAPI'
import { buildCustomEvent } from '../insights/utils'

import { addCartItem } from './handleCartItems'
import { useCart } from './useCart'

export interface UseBuyButtonPDPProps {
  item: CartItem | null
  boxType?: string | null
  boxDiscount?: number | null
  recurringOrder: boolean
}

export const useBuyButtonPDP = ({
  item,
  boxType,
  boxDiscount,
  recurringOrder,
}: UseBuyButtonPDPProps) => {
  const { addItem: defaultAddItem, id: cartId } = useCart()
  const {
    currency: { code },
    person,
  } = useSession()

  const { trackCustomEvent } = useApplicationInsightsAPI()
  const { pageType } = usePageTypeContext()

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault()

      if (!item) {
        return
      }

      const itemCategory = item.itemOffered.breadcrumbList?.itemListElement
        .slice(0, -1)
        .map((x) => x.name)

      const itemListName = itemCategory.join(' ')

      const isKit = item.isKit

      sendAnalyticsEvent<UnknownEvent>({
        name: 'add_to_cart',
        params: {
          currency: code as CurrencyCode,
          value: item.price * item.quantity,
          orderFormId: cartId,
          // Adding optional kit parameters
          ...(isKit && {
            bundle_label: item.itemOffered.isVariantOf.name,
            bundle_discount: +(item.listPrice - item.price).toFixed(2),
            bundle_discount_percentage: +(
              1 -
              item.price / item.listPrice
            ).toFixed(2),
          }),
          items: [
            {
              index: 0,
              item_list_id: '',
              item_category: itemCategory,
              item_list_name: itemListName,
              item_id: item.itemOffered.isVariantOf.productGroupID,
              item_name: item.itemOffered.isVariantOf.name,
              item_brand: item.itemOffered.brand.name,
              item_variant: item.itemOffered.sku,
              quantity: item.quantity,
              price: item.price,
              discount: +(item.listPrice - item.price).toFixed(2),
              full_price: item.listPrice,
              discount_percentage: +(1 - item.price / item.listPrice).toFixed(
                2
              ),
              currency: code as CurrencyCode,
              item_variant_name: item.itemOffered.name,
              product_reference_id: Number(item.itemOffered.gtin),
              coupon: null,
              box_type: boxType,
              box_quantity: item.quantity,
              box_discount:
                boxType === 'progressive buy box' ? boxDiscount : null,
              recurring_order: recurringOrder ? 'yes' : 'no',
              // Adding optional kit parameters
              ...(isKit && {
                bundle_label: item.itemOffered.isVariantOf.name,
                bundle_discount: +(item.listPrice - item.price).toFixed(2),
                bundle_discount_percentage: +(
                  1 -
                  item.price / item.listPrice
                ).toFixed(2),
              }),
            },
          ],
        },
      })

      addCartItem(item, cartId).then(() => {
        defaultAddItem(item)

        try {
          trackCustomEvent(
            buildCustomEvent(
              item,
              person?.email,
              cartId,
              pageType,
              'add-to-cart-button-PDP'
            ),
            'add-to-cart'
          )
        } catch (er) {
          console.log(er)
        }
      })
    },
    [defaultAddItem, code, item, recurringOrder, boxType, cartId]
  )

  return {
    onClick,
    'data-testid': 'buy-button',
    'data-sku': item?.itemOffered.sku,
    'data-seller': item?.seller.identifier,
  }
}
